<ui-toolbar-top [title]="headerTitle"
                [backVisible]="true">
</ui-toolbar-top>

@if (userDocuments$ | async; as userDocuments)
{

<div #content class="content" cdkScrollable>


    @for (type of userDocuments.types; track $index) {

      <ui-section-header #types [label]="type.title" [divider]="true" [expanded]="$index < 1"></ui-section-header>

      @if (isExpanded($index)) {

        <mat-toolbar>

          <span class="spacer"></span>

          <button mat-icon-button [disabled]="exports?.selectedOptions.selected.length != 1" (click)="onDownload()">
            <mat-icon>file_download</mat-icon>
          </button>

          <button mat-icon-button [disabled]="exports?.selectedOptions.selected.length < 1" (click)="onDelete()">
            <mat-icon>delete</mat-icon>
          </button>

        </mat-toolbar>

        <mat-selection-list #exports>

        @for(document of type.documents; track $index) {

        <mat-list-option [value]="document">
          <span matListItemTitle>{{document.name}}</span>
          <span matListItemLine >{{document.length  | fileSize}} {{document.lastModified | date:'medium':null:me.locale}}</span>
        </mat-list-option>

        }

        </mat-selection-list>

      }

    }


</div>

}


<ui-btn-fab icon="vertical_align_top" [class.visible]="scrolled" (onClick)="scrollToTop()"></ui-btn-fab>
