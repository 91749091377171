
<ui-toolbar-top [title]="title" (back)="onBack()">

          <span class="spacer"></span>

          @if (cartItemList?.cart?.items.length) {

          <button mat-icon-button class="menu-btn" [matMenuTriggerFor]="cartMenu" [disabled]="!ssv.any">
            <mat-icon class="material-icons-outlined">file_download</mat-icon>
          </button>

          }

</ui-toolbar-top>

@if (step == 'items') {

<ui-cart-item-list #cartItemList
                   class="step"
                   (onNext)="step = 'delivery'"></ui-cart-item-list>

} @else if (step == 'delivery') {

<ui-cart-deliveryoption-list class="step"
                             (onPrevious)="step = 'items'"
                             (onNext)="step = 'form'"></ui-cart-deliveryoption-list>

} @else if (step == 'form') {


<ui-cart-form class="step"
              (onPrevious)="step = 'delivery'"
              (onNext)="step = 'summary'"></ui-cart-form>

} @else if (step == 'summary') {

<ui-cart-summary class="step"
                 (onPrevious)="step = 'form'"
                 (onNext)="cartSent()"></ui-cart-summary>

} @else if (step == 'sent') {

<ui-cart-sent class="step"
              (onNext)="onBack()"></ui-cart-sent>

}

<mat-menu #cartMenu="matMenu">

    @for (template of cartItemList?.cart?.templates; track $index) {

    <button mat-menu-item (click)="export(template.id)">
      <span>{{template.name}}</span>
    </button>

    }

</mat-menu>
