import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, NgZone, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { MatListItemLine, MatListItemTitle, MatListOption, MatSelectionList } from '@angular/material/list';
import { Title } from '@angular/platform-browser';
import { Observable, mergeMap, of } from 'rxjs';
import { IUiUserDocuments } from '../../models/ui.interface';
import { FileSizePipe } from '../../pipes/file-size.pipe';
import { MeService } from '../../services/me.service';
import { UiBtnFabComponent } from '../ui-btn-fab/ui-btn-fab.component';
import { UiScrollableComponent } from '../ui-scrollable/ui-scrollable.component';
import { UiSectionHeaderComponent } from '../ui-section-header/ui-section-header.component';
import { UiToolbarTopComponent } from '../ui-toolbar-top/ui-toolbar-top.component';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import _ from 'lodash';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
  selector: 'view-user-documents',
  templateUrl: './view-user-documents.component.html',
  styleUrls: ['./view-user-documents.component.css'],
  standalone: true,
  imports: [UiToolbarTopComponent, CdkScrollable, AsyncPipe, UiBtnFabComponent, UiSectionHeaderComponent,
    MatSelectionList, MatListOption, MatListItemTitle, MatListItemLine, MatRipple, DatePipe, FileSizePipe,
    MatIconButton, MatIcon, MatToolbar]
})
export class ViewUserDocumentsComponent
  extends UiScrollableComponent
  implements OnInit {

  @ViewChild('content', { static: false })
  set content(element: ElementRef<HTMLElement>) {
    this.scrollElement = element;
  }

  @ViewChildren('types')
  types: QueryList<UiSectionHeaderComponent>;

  @ViewChild('exports', { static: false })
  exports: MatSelectionList;

  // UserDocuments
  public userDocuments$: Observable<IUiUserDocuments>;
  public userDocuments: IUiUserDocuments;

  // properties
  public get headerTitle(): string {

    return this.userDocuments?.title;

  }

  constructor(
    me: MeService,
    renderer: Renderer2,
    host: ElementRef,
    private title: Title,
    scrollDispatcher: ScrollDispatcher,
    zone: NgZone) {

    super(me, renderer, host, scrollDispatcher, zone)

  }

  override ngOnInit(): void {

    // call base implementation
    super.ngOnInit();

    this.reload();

  }

  private reload() {

    this.userDocuments$ = this.loadDocuments()
      .pipe(
        mergeMap(documents => this.updateDocuments(documents))
      );

  }

  private loadDocuments(): Observable<IUiUserDocuments> {

    return this.me.call<IUiUserDocuments>('browse.user.documents');

  }

  private updateDocuments(documents: IUiUserDocuments): Observable<IUiUserDocuments> {

    // nothing to do
    if (!documents)
      return of(documents);

    // update CSS and style
    super.update(documents);

    // set page
    this.userDocuments = documents;

    // set page title
    if (this.userDocuments.title) {
      this.title.setTitle(`${this.me.domainTitle} - ${this.userDocuments.title}`);
    }

    // scroll to top
    this.scrollElement?.nativeElement.scrollTo(0, 0);
    this.scrollLastOffset = 0;
    this.scrolled = false;
    this.scrollDir = null;

    return of(documents);
  }

  public scrollToTop() {

    this.scrollElement.nativeElement.scrollTo({ behavior: 'smooth', top: 0 });

  }

  isExpanded(index: number) {
    return this.types.get(index)?.expanded;
  }

  public onDelete() {

    let dialogRef = this.me.openConfirmDialog('dlg-delete', '');

    dialogRef.afterClosed().subscribe(dlgResult => {

      if (dlgResult == true) {

        this.me.call<any>('browse.user.documents.delete', {

          exports: _.map(this.exports.selectedOptions.selected, 'value.url')

        }).subscribe(() => {

          this.reload();

        })

      }

    });

  }

  public onDownload() {

    if (this.exports.selectedOptions.selected.length == 1) {

      let option = this.exports.selectedOptions.selected[0].value;
      this.me.mediaDownloadAuthenticated(`api/${option.url}`, option.name);

    }

  }


}
