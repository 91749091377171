
@if (any) {

  @for (note of notes; track $index) {

  <ui-user-note [note]="note" (click)="onClick(note)"></ui-user-note>

  }

}
@else {

  <div class="label-large">{{text.help}}</div>

}

